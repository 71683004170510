<template>
  <b-form-group
    label="Bağlantı Hedef"
    label-for="position"
  >
    <validation-provider
      #default="{ errors }"
      name="Bağlantı Hedef"
      rules="required"
    >
      <v-select
        v-model="dataItem.target"
        :options="targets"
        label="title"
        :reduce="item => item.value"
        placeholder="Seçiniz"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'TargetSelect',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
      targets: [
        { title: 'Yeni Sekmede', value: 'blank' },
        { title: 'Aynı Pencerede', value: 'self' },
      ],
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['buttons/dataItem']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
