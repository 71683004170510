<template>
  <div>
    <b-card>
      <title-text />
      <target-select />
      <ordering-text />
      <url-text />
    </b-card>
    <image-card />
  </div>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import TitleText from '@/views/Admin/Buttons/elements/TitleText.vue'
import TargetSelect from '@/views/Admin/Buttons/elements/TargetSelect.vue'
import OrderingText from '@/views/Admin/Buttons/elements/OrderingText.vue'
import UrlText from '@/views/Admin/Buttons/elements/UrlText.vue'
import ImageCard from '@/views/Admin/Buttons/elements/ImageCard.vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    TitleText,
    TargetSelect,
    OrderingText,
    UrlText,
    ImageCard,
  },
}
</script>
